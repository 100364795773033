import React from "react";
import withLayout from "../../layout";
import SEO from "../../components/SEO";
import {injectIntl} from "react-intl";
import SingleInternshipPage from "../../components/SingleInternshipPage";

/**
 * note that the key we defined in role is needed for building the SingleJobPage
 * component with the right translation strings and media
 * @returns { SingleJobPage }
 * @constructor
 */

const SingleInternshipPageWrapper = ({intl}) => {
    const IntlTextObj = {
        role: "internships.internship_type1",
    };
    const keywords = intl.formatMessage({id:'internships.meta_keywords'}).split(',') || [];
    return <>
        <SEO title={intl.formatMessage({id: 'internships.meta_title'})}
             description={intl.formatMessage({id: 'internships.meta_description'})} keywords={keywords} lang={intl.locale}/>
        <SingleInternshipPage IntlTextObj={IntlTextObj} intl={intl}/>
    </>
};

const customProps = {
    localeKey: "internships", // same as file name in src/i18n/translations/your-lang/index.js
};

export default withLayout(customProps)(injectIntl(SingleInternshipPageWrapper));
